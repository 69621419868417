<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="breadcrumb">
      <h1>Modificar Aseguradora <span class="h4 pl-2"> N°: {{correlativo}}</span></h1>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <form @submit.prevent="modificarAseguradora">
            <div class="card-header">
              <div class="col-sm-5">
                <button type="submit"
                 class="btn bg-custom-green mr-2 rounded"
                 :disabled="buttonDisabled">
                 Guardar
                </button>
                <router-link :to="{ name: 'aseguradora-index' }">
                  <button class="btn btn-white border border-light rounded">
                    Regresar
                  </button>
                </router-link>
              </div>
            </div>
            <div class="card-body pb-0">
              <div class="row mb-4">
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <div class="col-sm-3 pr-0 text-right">
                      <label class="col-form-label">Cliente:
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <multiselect
                        v-model="clienteSelected"
                        :options="clientes"
                        placeholder=""
                        label="nombre"
                        track-by="id"
                        select-label=""
                        deselect-label="X"
                        :disabled="hasMovimientos">
                        <span slot="caret" v-if="!(clienteSelected===null)"></span>
                        <span slot="noResult">Sin Resultados</span>
                      </multiselect>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-3 pr-0 text-right">
                      <label class="col-form-label">
                        Contacto 1:
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <input
                        type="text"
                        v-model="contacto1"
                        class="form-control text-center"
                        autocomplete="off"
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3 pr-0 text-right">
                      <label class="col-form-label">
                        Contacto 2:
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <input
                        type="text"
                        v-model="contacto2"
                        class="form-control text-center"
                        autocomplete="off"
                      >
                    </div>
                  </div>
                <div class="row mt-2 justify-content-center">
                  <div class="col-sm-6 pr-0 text-left">
                    <div class="form-group form-check">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1">
                      <label class="form-check-label" for="exampleCheck1">
                        No imprime Diagnóstico
                      </label>
                      <div class="icon-container ml-2">
                        <svg @mouseover="hover = true" @mouseleave="hover = false" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0,0,256,256"
                            style="fill:#FFFFFF;">
                            <g fill="#e91414" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.6907,0 -23,10.3093 -23,23c0,12.69071 10.3093,23 23,23c12.69071,0 23,-10.30929 23,-23c0,-12.6907 -10.30929,-23 -23,-23zM25,4c11.60982,0 21,9.39018 21,21c0,11.60982 -9.39018,21 -21,21c-11.60982,0 -21,-9.39018 -21,-21c0,-11.60982 9.39018,-21 21,-21zM25,11c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3zM21,21v2h1h1v13h-1h-1v2h1h1h4h1h1v-2h-1h-1v-15h-1h-4z"></path></g></g>
                          </svg>
                        <div class="info-message" :class="{ 'visible': hover }">Esto solo afecta al imprimir en internacion/C E en receta</div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <div class="col-sm-4 pr-0 text-right">
                      <label class="col-form-label">
                        Nit:
                      </label>
                    </div>
                    <div class="col-sm-5">
                      <input
                        type="text"
                        :value="nit"
                        class="form-control text-center"
                        autocomplete="off"
                        disabled
                      >
                    </div>
                    <div class="col-sm-3">
                      <button type="button" class="btn btn-warning" @click="descargarSeguros">
                        Exportar
                      </button>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-4 pr-0 text-right">
                      <label class="col-form-label">
                        Celular:
                      </label>
                    </div>
                    <div class="col-sm-2">
                      <multiselect
                        v-model="paisSelected"
                        :options="paises"
                        :allow-empty="false"
                        :show-labels="false"
                        :searchable="false"
                        :close-on-select="true"
                        label="descripcion"
                        track-by="descripcion"
                        @input="setCodigoPais">
                        <template slot="singleLabel" slot-scope="props">
                          <img class="option__image"
                            :src="props.option.img"
                            :alt="props.option.descripcion"
                            width="26px">
                        </template>
                        <template slot="option" slot-scope="props">
                          <img class="option__image"
                            :src="props.option.img"
                            :alt="props.option.descripcion"
                            width="26px">
                        </template>
                      </multiselect>
                    </div>
                    <div class="col-sm-3 pl-0 py-1">
                      <input type="text"
                        class="form-control"
                        :placeholder="'+'+codigoPais"
                        v-model="celular1"
                        autocomplete="off"
                        @keydown="validarCaracter"
                        @blur="reValidarCelular1">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4 pr-0 text-right">
                      <label class="col-form-label">
                        Celular:
                      </label>
                    </div>
                    <div class="col-sm-2">
                      <multiselect
                        v-model="paisSelected2"
                        :options="paises"
                        :allow-empty="false"
                        :show-labels="false"
                        :searchable="false"
                        :close-on-select="true"
                        label="descripcion"
                        track-by="descripcion"
                        @input="setCodigoPais2">
                        <template slot="singleLabel" slot-scope="props">
                          <img class="option__image"
                            :src="props.option.img"
                            :alt="props.option.descripcion"
                            width="26px">
                        </template>
                        <template slot="option" slot-scope="props">
                          <img class="option__image"
                            :src="props.option.img"
                            :alt="props.option.descripcion"
                            width="26px">
                        </template>
                      </multiselect>
                    </div>
                    <div class="col-sm-3 pl-0 py-1">
                      <input type="text"
                        class="form-control"
                        :placeholder="'+'+codigoPais2"
                        v-model="celular2"
                        autocomplete="off"
                        @keydown="validarCaracter"
                        @blur="reValidarCelular2">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4 pr-0 text-right">
                      <label class="col-form-label">
                        Tipo Seguro:
                      </label>
                    </div>
                    <div class="col-sm-5">
                      <multiselect
                          v-model="tipoSeguroSelected"
                          :options="tipoSeguro"
                          placeholder=""
                          label="nombre"
                          track-by="id"
                          select-label=""
                          deselect-label="X"
                          disabled>
                          <span slot="noResult">Sin Resultados</span>
                        </multiselect>
                    </div>
                  </div>
                  <div v-if="tipoSeguroSelected.id === 2">
                  <div class="row justify-content-center">
                  <div class="col-lg-6 col-md-4 col-sm-6">
                    <div
                        class="p-1 mb-2"
                        style="border-radius: 5px; border: 1px solid orange;"
                      >
                        <div class="row align-items-center">
                          <div class="col-3">
                            <label style="font-size: 0.7rem; margin: 0;">Límite:</label>
                          </div>
                          <div class="col-5">
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              placeholder="Límite"
                              v-model="limiteMonetario"
                              style="font-size: 0.7rem;"
                            />
                          </div>
                          <div class="col-3">
                            <label style="font-size: 0.7rem; margin: 0;">{{ moneda }}</label>
                          </div>
                        </div>
                      </div>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
              </div>
            </form>
            <sectionTabServicesAndProductos
            v-if="servicios.length > 0 &&
            categoriaAseguradoras.length > 0 ||
            situaciones ||
            listaTabla.length > 0 ||
            listaTablaProductos.length > 0 ||
            datosHabitacion.length > 0 "
            :servicios="servicios"
            :categoriaAseguradoras="categoriaAseguradoras"
            :situaciones="situaciones"
            :listaTablaOut="listaTabla"
            :listaTablaProductosOut="listaTablaProductos"
            :listaTablaCantidadOut = "listaTablaCantidad"
            :datosHabitacionOut="datosHabitacion"
            :edit="true"
            :tipoSeguroSelected="tipoSeguroSelected"
            :moneda="moneda"
            @update-lista-tabla="actualizarListaTabla"
            @update-lista-tabla-productos="actualizarListaTablaProductos"
            @update-datos-habitacion="actualizarDatosHabitacion"
            @update-lista-tabla-cantidad="actualizarListaTablaCantidad"
          />
        </div>
      </div>
    </div>
   </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';
import sectionTabServicesAndProductos from '../components/sectionTabServicesAndProductos.vue';

export default {
  name: 'edit',
  components: {
    Multiselect,
    // eslint-disable-next-line vue/no-unused-components
    VueNumeric,
    sectionTabServicesAndProductos,
  },
  data() {
    return {
      isLoading: true,
      id: atob(this.$route.params.id),
      correlativo: '',
      contacto1: '',
      contacto2: '',
      contactoId1: 0,
      contactoId2: 0,
      codigoPais: '',
      codigoPais2: '',
      paisSelected: null,
      paisSelected2: null,
      paises: [],
      clienteSelected: null,
      clientes: [],
      serviciosSelected: null,
      productoSelected: null,
      servicios: [],
      productos: [],
      celular1: '',
      celular2: '',
      coaseguro: 0,
      seguro: 0,
      listaTabla: [],
      listaTablaProductos: [],
      situaciones: null,
      buttonDisabled: false,
      categoriaSelected: null,
      categoriaAseguradoras: [],
      modoEdicionTabla: false,
      textButtonForm: 'Agregar',
      textButtonFormProd: 'AgregarProducto',
      selectedId: null,
      selectedCategoryId: null,
      hover: false,
      isArchivoActivo: false,
      isButtonDisabled: false,
      datosHabitacion: [
      ],
      tipoSeguro: [
      ],
      tipoSeguroSelected: null,
      moneda: '',
      limiteMonetario: null,
      listaTablaCantidad: [],
      cantMovimientos: 0,
    };
  },
  async created() {
    await this.cargarDatosIniciales();
  },
  methods: {
    async cargarDatosIniciales() {
      this.isLoading = true;
      try {
        const response = await axios.get(`/clinic/insurer/${this.id}/edit`);
        const { numero } = response.data.data.aseguradora;
        const personaId = response.data.data.aseguradora.persona_id;
        const { paises } = response.data.data;
        const { clientes } = response.data.data;
        const services = response.data.data.tipo_servicios;
        const { situaciones } = response.data.data;
        const { contactos } = response.data.data;
        const arrayAseguradoraServicios = response.data.data.aseguradora_servicios;
        const arrayAseguradoraServiciosCantidad = response.data.data.aseguradora_servicios_cantidad;

        const CATEGORIAS_ASEGURADORA = response.data.data.categoria_aseguradoras;
        this.listaTabla = response.data.data.aseguradora_servicios;
        this.listaTablaProductos = response.data.data.aseguradora_productos;
        this.cargarCorrelativo(numero);
        this.cargarPaises(paises);
        this.clientes = clientes;
        this.categoriaAseguradoras = CATEGORIAS_ASEGURADORA;
        this.cargarServicios(services);
        this.seleccionarCliente(personaId);
        this.cargarSituaciones(situaciones);
        this.cargarContactos(contactos);
        this.cargarAseguradoraServicios(arrayAseguradoraServicios);
        this.cargarAseguradoraServiciosCantidad(arrayAseguradoraServiciosCantidad);
        this.tipoSeguro = response.data.data.tipo_seguro.map((tipo) => {
          if (tipo.id === 3) {
            return {
              ...tipo,
              $isDisabled: true,
            };
          }
          return {
            ...tipo,
            $isDisabled: false,
          };
        });
        const seleccionadoTS = response.data.data.tipo_seguro_seleccionado;
        this.tipoSeguroSelected = seleccionadoTS[seleccionadoTS.length - 1];
        this.moneda = response.data.data.moneda;
        if (Array.isArray(response.data.data.limite_monetario) && response.data.data.limite_monetario.length > 0) {
          this.limiteMonetario = parseFloat(response.data.data.limite_monetario[0].limite_monetario);
        } else {
          this.limiteMonetario = 0;
        }
        this.cantMovimientos = response.data.data.has_movimientos;
        await this.cargarDatosHabitacion();
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async cargarDatosHabitacion() {
      const request = {
        params: {
          aseguradora_id: this.id,
        },
      };
      try {
        const response = await axios.get('/clinic/insurer/sala_cama', request);
        this.datosHabitacion = response.data.data.aseguradora_sala_cama;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    cargarCorrelativo(nro) {
      this.correlativo = nro;
    },
    cargarPaises(lista) {
      this.paises = lista;
    },
    cargarServicios(lista) {
      this.servicios = lista;
    },
    cargarProductos(lista) {
      this.productos = lista;
    },
    cargarSituaciones(lista) {
      this.situaciones = lista;
    },
    encontrarPais(paisId) {
      return this.paises.filter((el) => el.id === paisId)[0];
    },
    seleccionarCliente(personaId) {
      /* eslint-disable prefer-destructuring */
      this.clienteSelected = this.clientes.filter((el) => el.id === personaId)[0];
    },
    cargarContactos(arrayContactos) {
      this.paisSelected = this.paises[0];
      this.paisSelected2 = this.paises[0];
      this.codigoPais = this.paises[0].codigo_telefono;
      this.codigoPais2 = this.paises[0].codigo_telefono;
      if (arrayContactos.length === 1) {
        this.contacto1 = arrayContactos[0].nombre;
        this.contactoId1 = arrayContactos[0].id;
        this.paisSelected = this.encontrarPais(arrayContactos[0].pais_id);
        this.codigoPais = this.encontrarPais(arrayContactos[0].pais_id).codigo_telefono;
        this.celular1 = arrayContactos[0].telefono;
      } else if (arrayContactos.length === 2) {
        this.contacto1 = arrayContactos[0].nombre;
        this.contactoId1 = arrayContactos[0].id;
        this.paisSelected = this.encontrarPais(arrayContactos[0].pais_id);
        this.codigoPais = this.encontrarPais(arrayContactos[0].pais_id).codigo_telefono;
        this.celular1 = arrayContactos[0].telefono;
        this.contacto2 = arrayContactos[1].nombre;
        this.contactoId2 = arrayContactos[1].id;
        this.paisSelected2 = this.encontrarPais(arrayContactos[1].pais_id);
        this.codigoPais2 = this.encontrarPais(arrayContactos[1].pais_id).codigo_telefono;
        this.celular2 = arrayContactos[1].telefono;
      }
    },
    cargarAseguradoraServicios(lista) {
      const array = lista;
      array.forEach((item, index) => {
        array[index].servicio = this.servicios.filter((el) => (
          el.id === array[index].tipo_servicio_id
        ))[0].nombre;
        if (array[index].categoria_aseguradora_id !== null) {
          const ITEM_CATEGORIA = this.categoriaAseguradoras.find((el) => (
            el.id === array[index].categoria_aseguradora_id
          ));
          if (ITEM_CATEGORIA === undefined) {
            const ID = array[index].categoria_aseguradora_id;
            util.showNotify(`La categoria con el id ${ID} no existe`, 'error');
            array[index].categoria = '';
          } else {
            array[index].categoria = ITEM_CATEGORIA.nombre;
          }
        } else {
          array[index].categoria = '';
        }
        array[index].situacion = this.situaciones.filter((el) => (
          el.id === array[index].situacion_id
        ))[0].nombre;
        array[index].total = parseFloat(item.coa_seguro) + parseFloat(item.seguro);
        /* eslint-disable no-underscore-dangle */
        array[index]._rowVariant = array[index].estado ? '' : 'light';
        if (array[index].deleted === 1) {
          array[index]._rowVariant = 'danger';
        }
      });
      this.listaTabla = array;
    },
    cargarAseguradoraServiciosCantidad(lista) {
      const array = lista;
      array.forEach((item, index) => {
        array[index].servicio = this.servicios.filter((el) => (
          el.id === array[index].tipo_servicio_id
        ))[0].nombre;
        if (array[index].categoria_aseguradora_id !== null) {
          const ITEM_CATEGORIA = this.categoriaAseguradoras.find((el) => (
            el.id === array[index].categoria_aseguradora_id
          ));
          if (ITEM_CATEGORIA === undefined) {
            const ID = array[index].categoria_aseguradora_id;
            util.showNotify(`La categoria con el id ${ID} no existe`, 'error');
            array[index].categoria = '';
          } else {
            array[index].categoria = ITEM_CATEGORIA.nombre;
          }
        } else {
          array[index].categoria = '';
        }
        array[index].situacion = this.situaciones.filter((el) => (
          el.id === array[index].situacion_id
        ))[0].nombre;
        array[index].total = parseFloat(item.coa_seguro) + parseFloat(item.seguro);
        /* eslint-disable no-underscore-dangle */
        array[index]._rowVariant = array[index].estado ? '' : 'light';
        if (array[index].deleted === 1) {
          array[index]._rowVariant = 'danger';
        }
      });
      this.listaTablaCantidad = array;
    },
    modificarFila(data) {
      /* eslint-disable no-param-reassign */
      /* eslint-disable no-underscore-dangle */
      if (this.situaciones === null) {
        return;
      }
      if (data.item.estado) {
        data.item._rowVariant = '';
        data.item.situacion = this.situaciones[1].nombre;
        data.item.situacion_id = this.situaciones[1].id;
      } else {
        data.item._rowVariant = 'light';
        data.item.situacion = this.situaciones[0].nombre;
        data.item.situacion_id = this.situaciones[0].id;
      }
    },
    setCodigoPais() {
      this.codigoPais = this.paisSelected.codigo_telefono;
    },
    setCodigoPais2() {
      this.codigoPais2 = this.paisSelected2.codigo_telefono;
    },
    resetearInputServicio() {
      this.serviciosSelected = null;
    },
    activarModoEdicion() {
      this.modoEdicionTabla = true;
      this.textButtonForm = 'Modificar';
    },
    desactivarModoEdicion() {
      this.modoEdicionTabla = false;
      this.textButtonForm = 'Agregar';
    },
    activarModoEdicionProducto() {
      this.modoEdicionTablaProducto = true;
      this.textButtonFormProd = 'ModificarProducto';
    },
    desactivarModoEdicionProducto() {
      this.modoEdicionTabla = false;
      this.textButtonFormProd = 'AgregarProducto';
    },
    modificarAseguradora() {
      if (this.clienteSelected === null) {
        util.showNotify('Elija un Cliente ', 'warn');
        return;
      }
      if (this.tipoSeguroSelected.id === 2) {
        if (parseFloat(this.limiteMonetario) === 0 || this.limiteMonetario === null) {
          util.showNotify('El límite monetario debe ser mayor o igual a 1.', 'warn');
          return;
        }
      }
      const aseguradoraServicios = [];
      this.listaTabla.forEach((item) => {
        const status = item.estado === false ? 0 : 1;
        const obj = {
          id: item.id,
          tipo_servicio_id: item.tipo_servicio_id,
          categoria_aseguradora_id: item.categoria_aseguradora_id,
          situacion_id: item.situacion_id,
          comentario: null,
          deleted: item.deleted,
          coa_seguro: item.coa_seguro,
          seguro: item.seguro,
          estado: status,
        };
        aseguradoraServicios.push(obj);
      });
      const aseguradoraProductos = [];
      this.listaTablaProductos.forEach((item) => {
        const status = item.estado === false ? 0 : 1;
        const obj = {
          id: item.id,
          tipo_producto_id: item.tipo_producto_id,
          categoria_aseguradora_id: item.categoria_aseguradora_id,
          coa_seguro: item.coa_seguro,
          seguro: item.seguro,
          situacion_id: item.situacion_id,
          estado: status,
          cantidad_maxima_anual: item.cantidad_maxima_anual,
        };
        aseguradoraProductos.push(obj);
      });
      const aseguradoraSalaCama = [];
      this.datosHabitacion.forEach((item) => {
        const obj = {
          id: item.id,
          sala_cama_id: item.sala_cama_id,
          seguro: item.seguro,
          situacion_id: item.situacion_id,
        };
        aseguradoraSalaCama.push(obj);
      });
      const aseguradoraServiciosCantidad = [];
      this.listaTablaCantidad.forEach((item) => {
        const status = item.estado === false ? 0 : 1;
        const obj = {
          id: item.id,
          tipo_servicio_id: item.tipo_servicio_id,
          categoria_aseguradora_id: item.categoria_aseguradora_id,
          situacion_id: item.situacion_id,
          comentario: null,
          deleted: item.deleted,
          coa_seguro: 0,
          seguro: 0,
          estado: status,
          cantidad_maxima_anual: item.cantidad_maxima_anual,
        };
        aseguradoraServiciosCantidad.push(obj);
      });
      const contactos = [];
      if (this.contacto1 !== '') {
        contactos.push({
          id: this.contactoId1,
          nombre: this.contacto1,
          telefono: this.celular1,
          pais_id: this.paisSelected.id,
        });
      }
      if (this.contacto2 !== '') {
        contactos.push({
          id: this.contactoId2,
          nombre: this.contacto2,
          telefono: this.celular2,
          pais_id: this.paisSelected2.id,
        });
      }
      const request = {
        aseguradora: {
          id: this.id,
          nit: this.clienteSelected.nit,
          persona_id: this.clienteSelected.id,
        },
        contactos,
        aseguradora_servicios: aseguradoraServicios,
        aseguradora_productos: aseguradoraProductos,
        aseguradora_sala_cama: aseguradoraSalaCama,
        limite_monetario: parseFloat(this.limiteMonetario),
        aseguradora_servicios_cantidad: aseguradoraServiciosCantidad,
      };
      this.actualizarCambiosFormulario(request);
    },
    async actualizarCambiosFormulario(request) {
      this.buttonDisabled = true;
      try {
        const response = await axios.put(`/clinic/insurer/${this.id}`, request);
        util.showNotify(response.data.message, 'success');
        this.redirect({ name: 'aseguradora-index' });
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
        this.buttonDisabled = false;
      }
    },
    redirect(path) {
      this.$router.push(path);
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    reValidarCelular1() {
      if (this.celular1 === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.celular1)) {
        this.celular1 = '';
      }
    },
    reValidarCelular2() {
      if (this.celular2 === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.celular2)) {
        this.celular2 = '';
      }
    },
    async descargarSeguros() {
      try {
        const RESPONSE = await axios.get(`/clinic/insurer/export_seguros/${this.id}`, {
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'data:application/vnd.ms-excel' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        const A = document.createElement('a');
        A.href = DOWNLOAD_URL;
        A.download = 'Seguros.xlsx';
        document.body.appendChild(A);
        A.click();
        document.body.removeChild(A);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    toggleArchivoActivo() {
      this.isArchivoActivo = !this.isArchivoActivo;
    },
    openFileDialog() {
      const input = this.$refs.fileInput;
      if (input && input.click) {
        input.click();
      } else {
        console.error('Referencia a fileInput no válida');
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      console.log('Archivo seleccionado:', file ? file.name : 'Ninguno');
    },
    actualizarListaTabla(nuevaLista) {
      this.listaTabla = nuevaLista;
    },
    actualizarListaTablaProductos(nuevaLista) {
      this.listaTablaProductos = nuevaLista;
    },
    actualizarDatosHabitacion(nuevaLista) {
      this.datosHabitacion = nuevaLista;
    },
    actualizarListaTablaCantidad(nuevaLista) {
      this.listaTablaCantidad = nuevaLista;
    },
  },
  computed: {
    nit() {
      if (this.clienteSelected !== null) {
        return this.clienteSelected.nit;
      }
      return '';
    },
    hasMovimientos() {
      return this.cantMovimientos > 0;
    },
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.icon-container {
  position: relative;
  display: inline-block;
}
.info-message {
  display: none;
  position: absolute;
  top: -30px;
  right: 50%;
  transform: translateX(50%);
  background-color: rgba(36, 33, 33, 0.7);
  color: white;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  text-align: center;
  z-index: 1;
}
.visible {
  display: block;
}
.custom-tabs .nav-tabs .nav-item .nav-link.active {
  background-color: #f4b375;
  color: white !important;
}
.green{
  background-color: #76FF7A;
  color: white;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
